form {
    margin: 40px 0px;

    input {
        font-weight: 500;
        margin: 20px auto;
        height: 25px;
        font-size: 18px;
        border: 0px;
        border-radius: 10px;
        padding: 15px;
        background-color: rgba(255, 255, 255);
        outline: none;
        display: block;
        max-width: 78%;
        width: 80%;
        &:focus {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        }
    }
}