.wheel__container {
  position: relative;
  margin: 10px auto;
}

li:first-child .text {
  background-color: rgb(235, 235, 235);
}

li:nth-child(2) .text{
  background-color: #00c18c;
}

li:nth-child(3) .text{
  background-color: rgb(235, 235, 235);;
}

li:nth-child(4) .text{
  background-color: #00c18c;
}

li:nth-child(5) .text{
  background-color: rgb(235, 235, 235);;
}

li:nth-child(6) .text{
  background-color: rgb(202, 201, 101);
}

li:first-child {
  transform: rotate(0deg) skewY(-30deg);
}

li:nth-child(2) {
  transform: rotate(60deg) skewY(-30deg);
}

li:nth-child(3) {
  transform: rotate(120deg) skewY(-30deg);
}

li:nth-child(4) {
  transform: rotate(180deg) skewY(-30deg);
}

li:nth-child(5) {
  transform: rotate(240deg) skewY(-30deg);
}

li:nth-child(6) {
  transform: rotate(300deg) skewY(-30deg);
}

.circle {
  width: 350px;
  height: 350px;
  border: 6px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 0;
  margin: auto;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
}


li {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}

.text {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  display: block;
  transform: skewY(30deg) rotate(28deg);
  padding-top: 20px;
  cursor: pointer;
  font-weight: 800;
  font-size: 18px;
}

.arrow {
  position: absolute;
  width: 15px;
  height: 25px;
  border-radius: 10px 10px 50px 50px;
  DISPLAY: block;
  background-color: #3c3c3c;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  margin: 0% 50%;
  left: -7.5px;
  z-index: 1;
}

.spin-button {
  display: block;
  margin: 40px auto;
  width: 80%;
  max-width: 100%;
  padding: 15px;
  font-weight: 800;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 0px solid;
  cursor: pointer;
  color: #00c18c;
  font-size: 18px;
  outline: none;
  
  &:hover{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  }
} 

.start-rotate { 
  animation: start-rotate 4s linear;
}

@keyframes start-rotate {
  100% {
    transform: rotate(5000deg);
  }
}

.stop-rotate {
  animation-play-state: paused;
}

.brand__rueda{
  max-width: 105px;
}

.subtitulo__rueda{
  text-align: center;
  margin: 40px auto;
  color: white;
}

.circle__interior{
  width: 50px;
  height: 50px;
  background-color: #00c18c;
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
  margin-left: 50%;
  left: -25px;
  top: 50%;
  margin-top: -25px;
  position: absolute;
  z-index: 1;
}

a{
  text-decoration: none;
}