@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');

*{
    font-family: 'Inter', sans-serif;
  }

body{
    background-color: #00c18c;
}

.title__sponsors{
    font-weight: 800;
    text-align: center;
    color: white;
    display: grid;
    grid-template: "a a" auto;
    align-items: center;
    max-width: 500px;
    margin: auto;
}

.banner__sponsors{
    max-width: 100%;
}

.nuevachance{
    text-align: center;
    h2{
        color: white;
    }
    p {
        color: white;
    }
    .card__follow{
        display: grid;
        grid-template: 'a' auto;
        margin: auto auto;
        background-color: white;
        width: 80%;
        border-radius: 10px;
    }
    .menu_buttons{
        display: grid;
        grid-template: "a a" auto;
        margin: auto;
    }
    .disabled{
        background-color: rgb(211, 211, 211);
        color: rgb(165, 165, 165);
        cursor: not-allowed;
        &:hover{
            box-shadow: none;
        }
    }
}

.monitor{
    color: white;
    h2{
        text-align: center;
    }
    p{
        color: black;
        background-color: white;
        padding: 5% 5%;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    }
    button{
        .picadas__contador{
            font-size: 12px;
        }
    }
}

.cuenta__follow{
    align-items: center;
    display: flex;
    margin: 20px 10px 20px 0px;
    /* width: 100%; */
    justify-content: center;
    img{
        margin: 0 20px;
    }
    a{
        background-color: #00c18c;
        color: white;
        padding: 10px;
        border-radius: 10px;
        transition: .25s;
        &:hover{
            background-color: #43caa6;
            transition: .25s;
        }
    }
}