.sponsors{
    align-items: center;
    display: grid;
    grid-template: "a" auto;
    justify-content: space-around;
}

.important__brand{
    max-width: 150px;
}

.brand{
    max-width: 80px;
}

